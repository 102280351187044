import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import moment from "moment-timezone"
import Disqus from "gatsby-plugin-disqus"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import Layout from "../../components/layout/Layout"
import Header from "../../components/header"
import SnsShare from "../../components/snsShare"
import Meta from "../../components/meta"

import styles from "./index.module.scss"
import "./index.scss"
import "../../styles/prism-onedark.css"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"

import LinkCard from "../../components/linkCard"
const shortcodes = { LinkCard }

export const BlogStaticPageTemplate = ({ post }) => {
  const url = "https://jackjasonb.com" + post.fields.slug

  return (
    <section className="section" id={styles.content}>
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{post.body}</MDXRenderer>
      </MDXProvider>
      <div style={{ marginTop: `4rem` }}>
        <hr />
        <span uk-icon="icon: clock" />
        {post.frontmatter.date}
        &nbsp;&nbsp;
        <span uk-icon="icon: future" />
        {post.frontmatter.modified}
      </div>
      <div className="contentFooter">
        <div className="share">
          <strong>Share</strong>
          <SnsShare title={post.frontmatter.title} url={url} />
        </div>
        <hr id={styles.divide} />
        <div className="comment">
          <strong>Comment</strong>
          <Disqus
            identifier={post.fields.slug}
            title={post.frontmatter.title}
            url={url}
          />
        </div>
      </div>
    </section>
  )
}

const BlogStaticPage = props => {
  const { data } = props
  const { mdx: post } = data
  const loc = post.fields.slug

  post.frontmatter.date = moment(post.frontmatter.date)
    .tz("Asia/Tokyo")
    .format("YYYY.MM.DD HH:mm")
  post.frontmatter.modified = moment(post.frontmatter.modified)
    .tz("Asia/Tokyo")
    .format("YYYY.MM.DD HH:mm")

  const description = (
    <>
      <span uk-icon="icon: clock" className={styles.ukIcon} />
      {post.frontmatter.date}
      <span uk-icon="icon: future" className={styles.ukIcon} />
      {post.frontmatter.modified}
    </>
  )
  const header = (
    <Header
      title={post.frontmatter.title}
      description={description}
      image={post.frontmatter.thumbnail.childImageSharp.fluid}
      loc={loc}
    />
  )
  var metaDescription =
    post.excerpt
      .replace(/\r?\n/g, "")
      .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
      .trim() + "..."
  return (
    <Layout loc={loc} header={header}>
      <Helmet
        title={`${post.frontmatter.title} | ${data.site.siteMetadata.title}`}
      />
      <Meta
        isRoot={false}
        title={`${post.frontmatter.title} | ${data.site.siteMetadata.title}`}
        imageUrl={post.frontmatter.thumbnail.publicURL}
        description={metaDescription}
      />
      <BlogStaticPageTemplate post={post} />
    </Layout>
  )
}

export default BlogStaticPage

export const pageQuery = graphql`
  query BlogStaticPageByID($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 120)
      body
      frontmatter {
        title
        date
        modified
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1920, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
      }
      fields {
        slug
      }
    }
  }
`
